/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Link = ({ property1, className, hasIcon = true, hasDiv = true }) => {
  return (
    <div className={`link ${className}`}>
      {hasIcon && (
        <div className={`icon property-1-4-${property1}`}>
          {["black-2", "black", "green-2", "green", "white-2", "white"].includes(property1) && (
            <img
              className="arrow"
              alt="Arrow"
              src={
                ["green", "white-2"].includes(property1)
                  ? "/img/arrow-1-1.svg"
                  : ["black-2", "green-2"].includes(property1)
                  ? "/img/arrow-1.svg"
                  : "/img/arrow-1-3.svg"
              }
            />
          )}

          {["simple-black", "simple-green", "simple-white"].includes(property1) && <>Label</>}
        </div>
      )}

      {hasDiv && (
        <>
          <>
            {["black-2", "black", "green-2", "green", "white-2", "white"].includes(property1) && (
              <div className={`text-wrapper-2 property-1-5-${property1}`}>Label</div>
            )}

            {["simple-black", "simple-green", "simple-white"].includes(property1) && (
              <img
                className="img"
                alt="Icon"
                src={
                  property1 === "simple-white"
                    ? "/img/icon-1.png"
                    : property1 === "simple-black"
                    ? "/img/icon.png"
                    : "/img/icon-2.png"
                }
              />
            )}
          </>
        </>
      )}
    </div>
  );
};

Link.propTypes = {
  property1: PropTypes.oneOf([
    "white",
    "black",
    "green-2",
    "simple-black",
    "white-2",
    "green",
    "simple-green",
    "simple-white",
    "black-2",
  ]),
  hasIcon: PropTypes.bool,
  hasDiv: PropTypes.bool,
};
