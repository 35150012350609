import React, { useState, useEffect } from 'react';
import { Heading } from "../../components/Heading";
import { Link } from "../../components/Link";
import { ImportCircle } from "../../icons/ImportCircle";
import { TwitterFill } from "../../icons/TwitterFill";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import "./style.css";
import React, { useState } from 'react';


export const Desktop = () => {

  const [email, setEmail] = useState('');
  const [successText, setSuccessText] = useState('');
  const [errorText, setErrorText] = useState('');
  const [warningText, setWarningText] = useState('');
  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }
  const spaceObjects = [
    {
      imageName: 'sora/sphere.png',
      position: { top: '10%', left: '15%' },
      size: { width: '50px', height: '50px' }
    },
    {
      imageName: 'sora/star-img.png',
      position: { top: '50%', left: '30%' },
      size: { width: '150px', height: '150px' }
    },
  ];
  const emailChange = (e) => {
    setEmail(e);
    document.querySelector('.warning-text').style.display = 'none';
  }

  const handleClick = () => {
    if (!validateEmail(email)) {
      setWarningText("Please enter a valid email address.");
      document.querySelector('.warning-text').style.display = 'block';
      return;
    }

    fetch('https://waitlist.cryptosora.io/submit-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
      .then(response => response.json())
      .then(data => {

        // if the response status code is not 200, throw an error
        if (data.status !== 200) {
          setErrorText(data.detail);
          document.querySelector('.error-text').style.display = 'block';
          document.querySelector('.field').style.display = 'none';
          document.querySelector('.email-button').style.display = 'none';
        } else {
          console.log('Success:', data);
          // get the details from the response and set the success-text to the details
          setSuccessText(data.detail);
          // document.querySelector('.success-text').innerHTML = data.detail;
          console.log(data.detail)
          // Show the success-text and hide the input field
          document.querySelector('.success-text').style.display = 'block';
          document.querySelector('.field').style.display = 'none';
          document.querySelector('.email-button').style.display = 'none';
        }
      })
      .catch((error) => {
        console.log("Already registered for whitelist")
        setErrorText(error.detail);
        document.querySelector('.error-text').style.display = 'block';
        document.querySelector('.field').style.display = 'none';
        document.querySelector('.email-button').style.display = 'none';
      });
  }


  return (
    <div className="desktop">
      <div className="div-2">
        <div className="glowy-line-section-header">
          <div className="glowy-line" />
          <div className="glowy-line-2" />
        </div>
        <div className="nav-bar">

          <div className="frame2-left">
            <img className="cryptopika-logo" alt="CryptoSora Logo" src="/img/Union.png" />
          </div>
          <div className="frame-2">
            <TwitterFill className="twitter-fill" />
            <a className="text-wrapper-5" href="#services">Launchpad</a>
            <a className="text-wrapper-5" href="#about-us">About Us</a>
            {/* a logo on the left side */}
          </div>
        </div>

        <div className="overlap-group">
          <div className="landing-page">
            {/* <div className="navigation-bar" /> */}
            <header className="header">
              <div className="frame">
                <img className="crypto-pika" alt="Crypto pika" src="img/sora/CryptoSora.png" />
                <center className="text-wrapper-3">The omni-chain decentralized prompt-to-video generative AI model and memecoin launchpad</center>
                <p className="success-text">{successText}</p>
                <p className="warning-text">{warningText}</p>
                <p className="error-text">{errorText}</p>
                <input className="field" placeholder="Email" type="email" value={email} onChange={e => emailChange(e.target.value)} />
                <div className="group">
                  <div className="overlap-group-2">
                    <button className="email-button" onClick={handleClick}>Join Waitlist</button>
                  </div>
                </div>
              </div>
              <div className="space-background lines"></div>
              <div className="space-background star-1"></div>
              <div className="space-background star-2"></div>
              <div className="space-background star-3"></div>
              <div className="space-background star-4"></div>
              <div className="space-background star-5"></div>
              <div className="space-background eth"></div>
              <div className="space-background sphere"></div>
              <div className="space-background sphere-1"></div>
              <div className="space-background bitcoin"></div>
            </header>
          </div>
        </div>


        <Carousel centerMode infiniteLoop autoPlay showThumbs={false}>
          <div>
            {/* load a video autoplay, muted, no controllers */}
            <video className='video-player' autoPlay muted loop>
              <source src="https://storage.cryptosora.io/face.mp4" type="video/mp4" />
            </video>
            <p className="legend grey"> <span className='bold white'>Prompt: </span>Warrior women looking at the viewer in the field</p>
          </div>
          <div>
            <video className='video-player' autoPlay muted loop>
              <source src="https://storage.cryptosora.io/cars_2.mp4" type="video/mp4" />
            </video>
            <p className="legend grey"> <span className='bold white'>Prompt: </span>Two car racing down the road</p>
          </div>
          <div>
            <video className='video-player' autoPlay muted loop>
              <source src="https://storage.cryptosora.io/bike.mp4" type="video/mp4" />
            </video>
            <p className="legend grey"> <span className='bold white'>Prompt: </span>Racing bike slowmotion leaning on corner</p>
          </div>
          <div>
            <video className='video-player' autoPlay muted loop>
              <source src="https://storage.cryptosora.io/sticker_2.mp4" type="video/mp4" />
            </video>
            <p className="legend grey"> <span className='bold white'>Prompt: </span>Papercut sticker of an orange flying dog astronaut going to the moon</p>
          </div>
          <div>
            <img className='video-player' src="https://storage.cryptosora.io/train.gif"
              alt="face" />
            <p className="legend grey"> <span className='bold white'>Prompt: </span> City train in the snowy streets passing by</p>
          </div>
        </Carousel>

        <div className="case-studies-block" id="about-us">
          <div className="case-studies">
            <div className="case-study">
              <center className="div-3">
                <span className="span">
                  Decentralized <br />
                  Computing Power
                  <br />
                </span>
                <span className="text-wrapper-6">
                  <br />
                  <br />
                  Millions of SoraNFT holders around the world will be able to provide  computing power to CryptoSora 24/7
                </span>
              </center>
              <Link className="link-instance" hasDiv={false} hasIcon={false} property1="simple-green" />
            </div>
            <img className="line" alt="Line" src="/img/line-4.svg" />
            <div className="case-study">
              <center className="div-3">
                <span className="span">
                  Decentralized <br />
                  Data Storage
                  <br />
                </span>
                <span className="text-wrapper-7">
                  <br />
                  <br />
                  All user data, prompt inputs, and output images are permanently stored in a decentralized manner on-chain
                </span>
              </center>
            </div>
            <img className="line" alt="Line" src="/img/line-4.svg" />
            <div className="case-study">
              <center className="decentralized">
                <span className="span">
                  Decentralized <br />
                  Ownership <br />
                </span>
                <span className="text-wrapper-6">
                  <br />
                  <br />
                  Unlike Sora or other centralized platforms who own everything their users create, CryptoSora empower users to truly own their creation
                </span>
              </center>
            </div>
          </div>
        </div>

        <div className="services-section-2" id="services">
          <div className="image-container">
            <div className="rectangle-text">
              <span className="span">
                Launchpad for Everyone<br /><br /><br />
              </span>
              <p className="service-p">Memes & NFTs are vital to crypto space, and with the power of AIGC, we revolutionize the landscape of launchpad and democratize access for all. <br /><br />With CryptoSora Launchpad, everyone can launch their own NFT collections or memecoins using just a few simple prompts, unlocking endless possibilities.</p>
            </div>
            <div className="footer-image-spaceship"></div>
          </div>
        </div>

        <div className="services-section-3" id="teams">
          <div className="team-title">
            <span>Team Background<br /><br /><br /></span>
          </div>
          <div className="team-img">
            <img src="https://storage.cryptosora.io/team-bg-1.png"></img>
          </div>
        </div>

        <div className="services-section-4" id="partnership">
          <div className="partnership-title">
            <span>Partners<br /><br /><br /></span>
          </div>
          <div className="partnership-img">
            <img src="https://storage.cryptosora.io/partnership.png"></img>
          </div>
        </div>


        <div className="glowy-line-section">
          <div className="glowy-line" />
          <div className="glowy-line-2" />
        </div>

        <div className="footer">
          <p className="p-footer">Copyright © 2024 CryptoSora. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};
