/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Heading = ({
  property1,
  className,
  labelClassName,
  divClassName,
  text = "Label",
  hasLabel = true,
  hasDiv = true,
}) => {
  return (
    <div className={`heading ${className}`}>
      <div className={`label ${property1} ${labelClassName}`}>
        <div className={`text-wrapper ${divClassName}`}>{text}</div>
      </div>
      {hasLabel && (
        <div className={`label-wrapper property-1-0-${property1}`}>
          <div className="div">{text}</div>
        </div>
      )}

      {hasDiv && (
        <div className={`div-wrapper property-1-2-${property1}`}>
          <div className="label-2">{text}</div>
        </div>
      )}
    </div>
  );
};

Heading.propTypes = {
  property1: PropTypes.oneOf(["black", "white", "green"]),
  text: PropTypes.string,
  hasLabel: PropTypes.bool,
  hasDiv: PropTypes.bool,
};
