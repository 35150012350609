/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const ImportCircle = ({ className }) => {
  return (
    <svg
      className={`import-circle ${className}`}
      fill="none"
      height="51"
      viewBox="0 0 51 51"
      width="51"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M33.4886 26.0505C34.1114 25.4284 34.1119 24.4193 33.4899 23.7966C32.8678 23.1738 31.8587 23.1733 31.236 23.7953L27.0781 27.9485L27.0781 5.84827C27.0781 4.96806 26.3645 4.25452 25.4843 4.25452C24.6041 4.25452 23.8906 4.96806 23.8906 5.84827L23.8906 27.9758L19.7054 23.7953C19.0827 23.1733 18.0736 23.1738 17.4515 23.7966C16.8295 24.4193 16.8301 25.4284 17.4528 26.0505L23.6634 32.2541C24.6619 33.2514 26.2796 33.2514 27.2781 32.2541L33.4886 26.0505Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M4.25391 29.7402L4.25391 29.5514C4.29364 28.8809 4.75433 28.2871 5.43526 28.1142C6.28362 27.8989 7.15257 28.4186 7.37612 29.275C8.44781 33.3806 10.807 37.0004 14.08 39.5784C17.3525 42.156 21.36 43.5521 25.4828 43.5521C29.6061 43.5521 33.6214 42.1555 36.904 39.5699C40.1871 36.9839 42.5515 33.3543 43.6249 29.2422C43.8472 28.3906 44.7154 27.8894 45.5641 28.1228C46.4128 28.3563 46.9205 29.2359 46.6982 30.0876C45.4522 34.861 42.7058 39.0829 38.8794 42.0969C35.0524 45.1112 30.3569 46.75 25.5188 46.75C20.6802 46.75 15.9768 45.1107 12.1397 42.0884C8.30322 39.0665 5.55163 34.8347 4.30394 30.0548C4.27651 29.9497 4.26007 29.8444 4.25391 29.7402Z"
        fill="url(#paint0_linear_20_309)"
        fillRule="evenodd"
      />
      <defs className="defs">
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_20_309"
          x1="25.5022"
          x2="25.5022"
          y1="28.0656"
          y2="46.75"
        >
          <stop className="stop" stopColor="#666AD1" />
          <stop className="stop" offset="0.270833" stopColor="#619CE3" />
          <stop className="stop" offset="0.645833" stopColor="#8FA293" />
          <stop className="stop" offset="1" stopColor="#E4CA91" />
        </linearGradient>
      </defs>
    </svg>
  );
};
